import React from 'react';
import { graphql } from 'gatsby';

import MainLayout from 'src/layout/MainLayout';
import { SectionHead, NewsletterForm } from 'src/components';

import content from 'src/content/aktuelles/newsletter';
import subpageNavigations from 'src/data/subpage-navigations';

const NewsletterAnmeldungPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline
    }}
    subpageNavItems={subpageNavigations.aktuelles}
    activeNavItem={3}
    useParentPathname
  >
    <section>
      <SectionHead headline={content.sectionHead.headline}>
        <p>{content.sectionHead.paragraph}</p>
      </SectionHead>
      <NewsletterForm />
    </section>
  </MainLayout>
);

export default NewsletterAnmeldungPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-in-white-bathroom-robe.jpg" }
    ) {
      ...image
    }
  }
`;
